export const SALES_CHANNELS = [
  { name: "Website", value: 1 },
  { name: "Whatsapp", value: 2 },
  { name: "Telegram", value: 3 },
  { name: "Twitter", value: 4 },
  { name: "Facebook", value: 5 },
  { name: "Instagram", value: 6 },
  { name: "Tik Tok", value: 7 },
  { name: "Threads", value: 8 },
  { name: "In Store", value: 9 },
];

export const BANKS = [
  { name: "Access Bank Plc", value: "Access Bank Plc" },
  { name: "Fidelity Bank Plc", value: "Fidelity Bank Plc" },
  {
    name: "First City Monument Bank Limited",
    value: "First City Monument Bank Limited",
  },
  {
    name: "First Bank of Nigeria Limited",
    value: "First Bank of Nigeria Limited",
  },
  {
    name: "Guaranty Trust Holding Company Plc",
    value: "Guaranty Trust Holding Company Plc",
  },
  { name: "Union Bank of Nigeria Plc", value: "Union Bank of Nigeria Plc" },
  { name: "United Bank for Africa Plc", value: "United Bank for Africa Plc" },
  { name: "Zenith Bank Plc", value: "Zenith Bank Plc" },
  { name: "Citibank Nigeria Limited", value: "Citibank Nigeria Limited" },
  { name: "Ecobank Nigeria", value: "Ecobank Nigeria" },
  { name: "Heritage Bank Plc", value: "Heritage Bank Plc" },
  { name: "Keystone Bank Limited", value: "Keystone Bank Limited" },
  { name: "Optimus Bank Limited", value: "Optimus Bank Limited" },
  {
    name: "Polaris Bank Limited. The successor to Skye Bank Plc.",
    value: "Polaris Bank Limited. The successor to Skye Bank Plc.",
  },
  { name: "Stanbic IBTC Bank Plc", value: "Stanbic IBTC Bank Plc" },
  { name: "Standard Chartered", value: "Standard Chartered" },
  { name: "Sterling Bank Plc", value: "Sterling Bank Plc" },
  { name: "Titan Trust Bank", value: "Titan Trust Bank" },
  { name: "Unity Bank Plc", value: "Unity Bank Plc" },
  { name: "Wema Bank Plc", value: "Wema Bank Plc" },
  { name: "Globus Bank Limited", value: "Globus Bank Limited" },
  { name: "Parallex Bank Limited", value: "Parallex Bank Limited" },
  { name: "PremiumTrust Bank Limited", value: "PremiumTrust Bank Limited" },
  { name: "Providus Bank Limited", value: "Providus Bank Limited" },
  {
    name: "SunTrust Bank Nigeria Limited",
    value: "SunTrust Bank Nigeria Limited",
  },
  { name: "Jaiz Bank Plc", value: "Jaiz Bank Plc" },
  { name: "Lotus Bank", value: "Lotus Bank" },
  { name: "TAJBank Limited", value: "TAJBank Limited" },
  {
    name: "Mutual Trust Microfinance Bank",
    value: "Mutual Trust Microfinance Bank",
  },
  { name: "Rephidim Microfinance Bank", value: "Rephidim Microfinance Bank" },
  {
    name: "Shepherd Trust Microfinance Bank",
    value: "Shepherd Trust Microfinance Bank",
  },
  {
    name: "Empire Trust Microfinance Bank",
    value: "Empire Trust Microfinance Bank",
  },
  {
    name: "Finca Microfinance Bank Limited",
    value: "Finca Microfinance Bank Limited",
  },
  {
    name: "Fina Trust Microfinance Bank",
    value: "Fina Trust Microfinance Bank",
  },
  { name: "Accion Microfinance Bank", value: "Accion Microfinance Bank" },
  { name: "Peace Microfinance Bank", value: "Peace Microfinance Bank" },
  { name: "Infinity Microfinance Bank", value: "Infinity Microfinance Bank" },
  {
    name: "Pearl Microfinance Bank Limited",
    value: "Pearl Microfinance Bank Limited",
  },
  {
    name: "Covenant Microfinance Bank Ltd",
    value: "Covenant Microfinance Bank Ltd",
  },
  {
    name: "Advans La Fayette Microfinance Bank",
    value: "Advans La Fayette Microfinance Bank",
  },
  { name: "FairMoney Microfinance Bank", value: "FairMoney Microfinance Bank" },
  { name: "Sparkle Bank", value: "Sparkle Bank" },
  { name: "Kuda Bank", value: "Kuda Bank" },
  {
    name: "Moniepoint Microfinance Bank",
    value: "Moniepoint Microfinance Bank",
  },
  { name: "Opay", value: "Opay" },
  { name: "Dot Microfinance Bank", value: "Dot Microfinance Bank" },
  { name: "Palmpay", value: "Palmpay" },
  { name: "Rubies Bank", value: "Rubies Bank" },
  { name: "VFD Microfinance Bank", value: "VFD Microfinance Bank" },
  { name: "Mint Finex MFB", value: "Mint Finex MFB" },
  { name: "Mkobo MFB", value: "Mkobo MFB" },
  { name: "Raven Bank", value: "Raven Bank" },
  { name: "Rex Microfinance Bank", value: "Rex Microfinance Bank" },
  { name: "Coronation Merchant Bank", value: "Coronation Merchant Bank" },
  { name: "FBNQuest Merchant Bank", value: "FBNQuest Merchant Bank" },
  { name: "FSDH Merchant Bank", value: "FSDH Merchant Bank" },
  { name: "Greenwich Merchant Bank", value: "Greenwich Merchant Bank" },
  { name: "Rand Merchant Bank", value: "Rand Merchant Bank" },
  { name: "Nova Merchant Bank", value: "Nova Merchant Bank" },
];
