import Dashboard from "./views/Dashboard";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: "",
    component: <Dashboard />,
  },
];
export default routes;
